import * as queryString from 'query-string';

(function() {
  /**
   * Generates a UUIDv4 string.
   *
   * @returns {string}
   */
  const uuidv4 = () => {
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g,
      (c) => {
        const r = Math.random() * 16 | 0;
        const v = c === 'x' ? r : (r & 0x3 | 0x8);
        return v.toString(16);
      });
  };

  /**
   * Gets a Long Session ID, either from a cookie or it creates one.
   * If it creates a new Long Session ID it will also assign the value
   * to the expected cookie for future use.
   *
   * @returns {string} - A UUIDv4
   */
  const getLongSessionId = () => {
    // TODO: Might want to change this cookie name.
    let longSessionId = getCookie('_talroo');
    if (!longSessionId) {
      longSessionId = uuidv4();
      setCookie('_talroo', longSessionId, 30);
    }

    return longSessionId;
  };

  /**
   * Grabs a number of client side data points for reporting.
   *
   * @param {string} sessionId
   *
   * @returns {
   *    {
   *    de: string,
   *    vw: number,
   *    cd: number,
   *    ce: boolean,
   *    ln: string,
   *    sw: number,
   *    tz: number,
   *    dl: *,
   *    dn: string,
   *    cn: {rt: *, dl: *, et: *},
   *    ua: string,
   *    dt: string,
   *    vh: number,
   *    sh: number,
   *    si: *,
   *    md: boolean,
   *    rl: string,
   *    oq: string,
   *    ol: string|number,
   *    }
   * }
   */
  const getClientInfo = (longSId, sId, qValue='', lValue='') => ({
    ce: window.navigator.cookieEnabled, // Cookies enabled
    ln: window.navigator.language, // Language
    dn: window.navigator.doNotTrack, // Do Not Track
    dl: window.location.href, // document location
    rl: document.referrer, // referrer location
    de: document.characterSet, // document encoding
    sh: window.screen.height, // screen resolution height
    sw: window.screen.width, // screen resolution width
    vh: window.innerHeight, // viewport size height
    vw: window.innerWidth, // viewport size width
    cd: window.screen.colorDepth, // color depth
    // Not supported globally https://developer.mozilla.org/en-US/docs/Web/API/Navigator/connection
    cn: {
      rt: safeGet(() => window.navigator.connection.rtt, ''),
      et: safeGet(() => window.navigator.connection.effectiveType, ''),
      dl: safeGet(() => window.navigator.connection.downlink, ''),
    },
    dt: document.title, // document title
    ua: window.navigator.userAgent, // user agent
    md: 'ontouchstart' in document, // is a mobile device?
    tz: (new Date()).getTimezoneOffset(), // timezone
    si: sId, // Short Session ID from page load
    li: longSId, // Long Session ID from Cookie
    oq: qValue, // Original Q value from client side
    ol: lValue, // Original L value from client side
  });

  /**
   * Sets a cookie's value.
   *
   * @param {string} cookieName
   * @param {string} cookieValue
   * @param {number} expirationInDays
   */
  const setCookie = (cookieName, cookieValue, expirationInDays) => {
    const d = new Date();
    d.setTime(d.getTime() + (expirationInDays * 24 * 60 * 60 * 1000));
    const expires = `expires=${d.toUTCString()}`;
    document.cookie = `${cookieName}=${cookieValue};${expires};path=/`;
  };

  /**
   * Grabs a cookie's value by name.
   *
   * @param {string} cookieName
   *
   * @returns {string}
   */
  const getCookie = (cookieName) => {
    const name = cookieName + '=';
    const decodedCookie = decodeURIComponent(document.cookie);
    const ca = decodedCookie.split(';');
    for (let i = 0; i < ca.length; i++) {
      let c = ca[i];
      while (c.charAt(0) === ' ') {
        c = c.substring(1);
      }
      if (c.indexOf(name) === 0) {
        return c.substring(name.length, c.length);
      }
    }
    return '';
  };

  /**
   * Runs a function that will pull an item nested deep within an Object. If
   * the function fails, it will return a default value.
   *
   * For example:
   * safeGet(()=>some.item.deeply.nested, 'some default')
   *
   * @param {function} fn
   * @param {any} defaultVal
   * @returns
   */
  const safeGet = (fn, defaultVal) => {
    try {
      return fn();
    } catch (e) {
      return defaultVal;
    }
  };

  document.onreadystatechange = function() {
    require('iframe-resizer-helper');

    let qValue = ''; // Original Q Value from client page.
    let lValue = ''; // Original L Value from client page.
    
    const sessionId = uuidv4(); // Short Page level Session ID. Shared by all Ad Units on the page
    const longSessionId = getLongSessionId(); // Long User level Session ID. Shard by all Ad Units loaded on the browser
    let clientInfo = {}

    const trackingEndpoint = 'https://te59819vs0.execute-api.us-east-1.amazonaws.com/prod';

    /**
     * Used to decode the `cid` data attribute on the Ad snippet.
     *
     * @param {string} cid - A base64 encoded string,
     *    contains a Talroo Customer ID
     * @returns {string}
     */
    const decodeAdCid = (cid) => {
      const decodedCid = atob(cid);
      return decodedCid.split('customer_id:')[1];
    };

    /**
     * Grabs various height and width info for use in infinite scroll in the
     * Ad iframe. Send the data into the widget space via a `postMessage`.
     *
     * @param {string} iframeId - An ID for an iframe
     */
    const getScrollData = (iframeId) => {
      const iframe = document.getElementById(iframeId);
      if(iframe){        
        const scrollPosition = getScrollXY();
        const iframeOffset = offset(iframe);
        const documentDimensions = getDocumentDimensions();
        iframe.iFrameResize && iframe.iFrameResize.resize()
        
        const data = {
          windowInnerHeight: window.innerHeight,
          windowClientHeight: window.document.documentElement.clientHeight,
          windowInnerWidth: window.innerWidth,
          windowClientWidth: window.document.documentElement.clientWidth,
          parentDocumentHeight: documentDimensions.height,
          parentDocumentWidth: documentDimensions.width,
          parentViewHeight: 0,
          parentViewWidth: 0,
          scrollY: scrollPosition.y,
          scrollX: scrollPosition.x,
          iframeTop: iframeOffset.top,
          iframeLeft: iframeOffset.left,
        };
        // Send the parent info into the widget.
        postMessage(
          iframe,
          'parent_scroll',
          data,
        );
      }
    };

    /**
     * Gets the current page offsets for the X and Y values.
     *
     * @param {Object} el - An HTML element
     *
     * @returns {boolean|{top: number, left: number}}
     */
    const offset = (el) => {
      if (!el) {
        return false;
      }
      const rect = el.getBoundingClientRect(),
        scrollLeft = window.pageXOffset
          || document.documentElement.scrollLeft,
        scrollTop = window.pageYOffset || document.documentElement.scrollTop;
      return { top: rect.top + scrollTop, left: rect.left + scrollLeft };
    };

    /**
     * Gets the current scroll offsets from the client side.
     *
     * See: https://developer.mozilla.org/en-US/docs/Web/API/Window/scrollY
     *
     * @returns {{x: number, y: number}}
     */
    const getScrollXY = () => {
      const supportPageOffset = window.pageXOffset !== undefined;
      const isCSS1Compat = ((document.compatMode || '') === 'CSS1Compat');
      const x = supportPageOffset
        ? window.pageXOffset : isCSS1Compat
          ? document.documentElement.scrollLeft : document.body.scrollLeft;
      const y = supportPageOffset
        ? window.pageYOffset : isCSS1Compat
          ? document.documentElement.scrollTop : document.body.scrollTop;
      return { x, y };
    };

    /**
     * Returns a function, that, as long as it continues to be invoked, will
     * not be triggered. The function will be called after it stops being
     * called for N milliseconds. If `immediate` is passed, trigger the
     * function on the leading edge, instead of the trailing.
     *
     * See: https://davidwalsh.name/javascript-debounce-function
     *
     * @param {Function} func - A function to apply the debounce to
     * @param {number} wait
     * @returns {function(): void}
     */
    function debounce(func, wait = 100) {
      let timeout;
      return function(...args) {
        clearTimeout(timeout);
        timeout = setTimeout(() => {
          func.apply(this, args);
        }, wait);
      };
    }

    /**
     * Gets the client side document dimensions
     *
     * @returns {{width: number, height: number}}
     */
    const getDocumentDimensions = () => {
      const body = document.body;
      const html = document.documentElement;

      const height = Math.max(
        body.scrollHeight,
        body.offsetHeight,
        html.clientHeight,
        html.scrollHeight,
        html.offsetHeight,
      );
      const width = Math.max(
        body.scrollWidth,
        body.offsetWidth,
        html.clientWidth,
        html.scrollWidth,
        html.offsetWidth,
      );
      return { height, width };
    };

    /**
     * Handles client side window resizes.
     */
    const reportWindowSize = () => {
      for (let visibleAd of (visibleAds || [])) {
        const { height, width } = visibleAd.getBoundingClientRect();
        visibleAd.children[0].height = height;
        visibleAd.children[0].width = width;
      }
    };

    /**
     * Creates an iframe element.
     *
     * @param {string} adId
     * @param {number} height
     * @param {number} width
     * @param {boolean} scroll
     * @param {string} src
     *
     * @returns {string}
     */
    const buildIframe = (adId, height, width, scroll, src) => {
      let p = ['<iframe'];
      p.push(`id="${adId}"`);
      p.push(`name="${adId}"`);
      p.push(`src="${src}"`);
      p.push('frameBorder="0" marginWidth="0"'
        + '        marginHeight="0" vspace="0" hspace="0" allowTransparency="true"'
        + `         allowFullScreen="true"`);
      p.push(
        'style="left:0;position:relative;top:0;border:0px;overflow:hidden;width:1px;min-width:100%;height:100%" scrolling="'
        + (scroll ? 'yes' : 'no') + '"');
      p.push('></iframe>');
      p = p.join(' ');
      return p;
    };

    let visibleAds = new Set();
    let previouslyVisibleAds = null;

    /**
     * Wrapper function to simplify the sendBeacon calls.
     *
     * https://developer.mozilla.org/en-US/docs/Web/API/Beacon_API/Using_the_Beacon_API
     *
     * @param {object} data
     * @param {string} url
     */
    const sendBeaconHelper = (data, url) => {
      // var xmlhttp = new XMLHttpRequest();   // new HttpRequest instance
      // xmlhttp.open("POST",
      // `${trackingEndpoint}/ad/XMLHttpRequest/event`);
      // xmlhttp.setRequestHeader("Content-Type", "text/plain");
      // xmlhttp.send(JSON.stringify(data)); xmlhttp.send('vincent_test');
      // https://developer.mozilla.org/en-US/docs/Web/API/Beacon_API/Using_the_Beacon_API
      let headers = {
        type: 'text/plain',
      };
      let blob = new Blob([JSON.stringify(data)], headers);
      navigator.sendBeacon(url, blob);
    };

    /**
     * Build a tracking message based on the provided parameters. Augments the message
     * with common envelope, tracking data and client info.
     *
     * @param {HTMLElement} ad - The Ad Unit ins element
     * @param {string} event_type
     * @param {object} data
     *
     * @returns {object}
     */
    const trackingMessage = (ad, event_type, data = {}) => {
      return {
        ad_id: ad._ad_id,
        type: event_type,
        data: Object.assign(
          data,
          ad._trackingData,
          {
            ad_sid: ad._session, // unique session for an Ad Unit instance
            ad_cid: ad._ad_cid, // customer identifier
            ad_type: ad._ad_type, //type of Ad Unit
            client_info: clientInfo
          },
          (ad._ad_id != ad._pad_id ? { pad_id: ad._pad_id } : {}) // parent Ad Unit ID (if it has one).
        ),
      }
    }

    /**
     * Used to get a value from the client page using a given location
     * and identifier.
     *
     * @param {string} loc - A supported location to grab the value from
     * @param {string} identifier - A string used to identify the value by
     * @param {string|number} dataAttribute - Back up value on the ad snippet
     *
     * @returns {string|number}
     */
    const getClientSideValue = (loc, identifier, dataAttribute) => {
      let value = '';
      if (loc === 'query') {
        const urlSearchParams = new URLSearchParams(window.location.search);
        value = urlSearchParams.get(identifier);
      } else if (loc === 'html') {
        const element = document.getElementById(identifier);
        value = element ? element.value : '';
      } else if (loc === 'data') {
        value = dataAttribute;
      } else {
        console.log(`Unsupported loc: ${loc}`);
      }

      return value || '';
    };

    /**
     * Gets an Ad's config data from the serving endpoint by ID.
     *
     * @param {string} adId - An Ad ID, UUIDv4
     * @param {number} customerId - A Talroo Customer ID
     *
     * @returns {Promise<Response | void>}
     */
    const getConfig = (adId, customerId) => {
      return fetch(
        `${trackingEndpoint}/ad/${adId}`,
        {
          method: 'GET',
          headers: {
            'X-Talroo-Customer-Id': decodeAdCid(customerId),
          },
        },
      ).then(response =>
        response.json(),
      ).catch(error =>
        console.error('error:', error),
      );
    };

    /**
     * Converts a query parameter string into an object for easier use.
     * Ex. foo=bar&woot=123 => {"foo": "bar", "woot": 123}
     *
     * @param {string} queryParameters
     * @returns {ParsedQuery}
     */
    const queryParametersToObject = (queryParameters) =>
      queryString.parse(queryParameters);



    /**
     * Used to grab the tracking values off of the Ad element or host page on the
     * client side and process the values for use downstream.
     *
     * @param {object} adElement - The Ad Unit ins element
     * @param {string} adId - This Ad Unit's id
     * @param {string} session - The session identifier for this Ad Unit instance
     * @returns {{t1: string, t2: string}}
     */
    const getTrackingData = (adElement, adId, session) => {
      const queryParameters = (location.search !== '')
        ? queryParametersToObject(location.search.substring(1))
        : {};
      const tLength = 32;

      //T2
      let t2 = 't2' in queryParameters ? queryParameters['t2'] : null;

      if (!t2) {
        const t2Raw = adElement.getAttribute('data-t2');
        t2 = t2Raw ? t2Raw.substring(0, tLength) : '';
      }

      //T1
      let t1 = 't1' in queryParameters ? queryParameters['t1'] : null;

      if (!t1) {
        t1 = session;
      }

      t1 = t1.replace(new RegExp('\-', 'g'), '');

      //Parent AdUnit
      let padId = 'padId' in queryParameters ? queryParameters['padId'] : adId;

      return {
        t1,
        t2,
        padId
      };
    };

    /**
     * Main logic for loading Ads on page.
     *
     * Finds the elements on page that should have Ads in them amd requests
     * the Ads details from the serving endpoint.
     *
     * Once the details are returned it creates the iframe hosting the
     * corresponding widget.
     *
     * Last, it resizes the iframe, if needed, and adds an onload listener to
     * pass in the Ad's properties via postMessage to the Widget page.
     */
    const reloadAds = () => {
      // Grab all the ad positions by class name.
      const ads = document.getElementsByClassName('talrooads');
      for (let adBox of ads) {
        if (!('implemented' in adBox.dataset)) {
          // Initialize dataset values.
          adBox.dataset.totalViewTime = 0;
          adBox.dataset.lastViewStarted = 0;
          adBox.dataset.visible = false;
          adBox.dataset.implemented = true;

          visibleAds.add(adBox);
          const { height, width } = adBox.getBoundingClientRect();

          // Add to the list on on page ads.
          const adId = adBox.getAttribute('data-ad-id');
          const customerId = adBox.getAttribute('data-ad-cid');

          getConfig(adId, customerId).then(r => {
            // Get the Q + L values based on the ad config setup.
            const { ad_props, session, widget_type } = r;
            const adInstanceId = `ad_${adId}_${session}`

            // Includes t1, t2, parent ad unit (if available)
            const trackingData = getTrackingData(adBox, adId, session);

            const { input_config, display_type } = ad_props;
            const { keyword_value, loc_value } = input_config;
            qValue = keyword_value
              ? getClientSideValue(
                keyword_value.loc,
                keyword_value.identifier,
                adBox.getAttribute('data-input-keyword'),
              )
              : '';
            lValue = loc_value
              ? getClientSideValue(
                loc_value.loc,
                loc_value.identifier,
                adBox.getAttribute('data-input-loc'),
              )
              : '';

            // Exclude q and l from extra params
            const { q, l, ...extraParams } = queryParametersToObject(window.location.search);

            clientInfo = getClientInfo(longSessionId, sessionId, qValue, lValue)

            const scroll = display_type in [
              'SplashPage',
              'FullSearch',
            ];

            // Set up the ad contents.
            adBox.appendChild(document.createRange().createContextualFragment(
              buildIframe(
                adInstanceId,
                height,
                width,
                scroll,
                `https://pop.talroo.com/widgets/v0/index.html`,
              ),
            ));

            const isFill = adBox.getAttribute('data-object-fit') === 'fill';
            if (!isFill){
              // Do not enable Frame Resize on `fill`. 
              // It assumes that the host of the Ad controls the units dimensions and
              // the Ad should stay within the assigned bounds. This will default the iFrame
              // to height 100% and the information propagated to the internal widget.
              // The widget can use this information to properly display within the
              // limited bounds.
              iFrameResize({ log: false, checkOrigin: false }, `#${adInstanceId}`);
            }

            const hostConfig = {
              "objectFit": isFill ? 'fill': 'none'
            }

            const newIframe = document.getElementById(adInstanceId);
            window.addEventListener(
              'scroll',
              debounce(() => getScrollData(adInstanceId), 500),
              false,
            );
            newIframe.onload = () => {
              postMessage(
                newIframe,
                'ad_config',
                Object.assign(
                  r,
                  {
                    ad_id: adId,
                    api_key: customerId,
                    loc: lValue,
                    keyword: qValue,
                    hostConfig,
                    trackingData,
                    extraParams,
                  },
                ),
              );
            };

            const { padId, ...tValues } = trackingData;

            /* MIXIN Behavior into INS tag */
            let ad_proto = {
              _iframe: newIframe,
              _ad_config: r,
              _ad_id: adId,
              _pad_id: padId,
              _ad_instance_id: adInstanceId,
              _ad_cid: decodeAdCid(customerId),
              _ad_type: widget_type,
              _l: function() {
                let loc_value = safeGet(
                  () => this._ad_config.ad_props.input_config.loc_value,
                );
                return loc_value
                  ? getClientSideValue(
                    loc_value.loc,
                    loc_value.identifier,
                    this.getAttribute('data-input-loc'),
                  )
                  : '';
              },
              _q: function() {
                let keyword_value = safeGet(
                  () => this._ad_config.ad_props.input_config.keyword_value,
                );
                return keyword_value
                  ? getClientSideValue(
                    keyword_value.loc,
                    keyword_value.identifier,
                    this.getAttribute('data-input-keyword'),
                  )
                  : '';
              },
              _session: session,
              _trackingData: tValues,
              refresh: function() {
                postMessage(
                  this._iframe,
                  'refresh',
                  {
                    loc: this._l(),
                    keyword: this._q(),
                  },
                );
              },
            };
            Object.assign(adBox, ad_proto);

            // send initial event of a session start
            const eMsg = trackingMessage(adBox, 'session')
            sendBeaconHelper(eMsg, `${trackingEndpoint}/ad/${adId}/event`);
          });
        }
      }
    };

    /**
     *
     * @param {Object} el
     * @param {string} type
     * @param {Object} data
     * @returns {boolean}
     */
    const postMessage = (el, type, data) => {
      if (!el) {
        return false;
      }
      el.contentWindow.postMessage(
        {
          type,
          data,
        },
        '*', //'https://pop.talroo.com',
      );
    };

    if (document.readyState === 'complete') {
      /**
       * Used to watch changes in the global TalrooAds array.
       *
       * @type {{set: (function(*, *, *, *): boolean), get: (function(*, *):
       *   *)}}
       */
      const arrayChangeHandler = {
        get: function(target, property) {
          // property is index in this case
          return target[property];
        },
        set: function(target, property, value, receiver) {
          target[property] = value;
          reloadAds();
          // you have to return true to accept the changes
          return true;
        },
      };

      const talrooAds = window.TalrooAds ? window.TalrooAds.slice() : [{}];
      window.TalrooAds = new Proxy(talrooAds, arrayChangeHandler);
      reloadAds();

      /**
       * Calculates if an Ad is at least 50% visible in the
       * viewport client side.
       */
      const isInViewport = () => {
        // https://developer.mozilla.org/en-US/docs/Web/API/Element/getBoundingClientRect
        for (let visibleAd of visibleAds) {
          const bounding = visibleAd.getBoundingClientRect();
          const vHalf = bounding.top + ((bounding.bottom - bounding.top) / 2);
          const hHalf = bounding.left + ((bounding.right - bounding.left) / 2);
          const visible = (
            bounding.top >= 0 &&
            bounding.left >= 0 &&
            vHalf <= (window.innerHeight
              || document.documentElement.clientHeight) &&
            hHalf <= (window.innerWidth
              || document.documentElement.clientWidth)
          );

          if (visibleAd.dataset.visible !== visible.toString()) {
            if (visibleAd.dataset.visible === 'false' && visible === true) {
              visibleAd.dataset.lastViewStarted = performance.now();
            } else {
              updateAdTimer(visibleAd);
              visibleAd.dataset.lastViewStarted = 0;
            }
            visibleAd.dataset.visible = visible;
          }
        }
      };

      /**
       * Updates an Ad's view time.
       *
       * @param {Object} adBox
       */
      const updateAdTimer = (adBox) => {
        let lastStarted = adBox.dataset.lastViewStarted;
        let currentTime = performance.now();

        let diff = 0;
        if (lastStarted !== '0') {
          diff = currentTime - lastStarted;
          adBox.dataset.totalViewTime = parseFloat(adBox.dataset.totalViewTime)
            + diff;
        }

        adBox.dataset.lastViewStarted = currentTime;
        // TODO: Add this as a debug option.
        // console.log(
        //   `${adBox.dataset.adId} was more than 50% visible for ${Math.floor(
        //     diff
        //     / 1000)}s, and has been visible on page for a total of
        // ${Math.floor( adBox.dataset.totalViewTime / 1000)}s.`);
      };

      /**
       * Updates an Ad's visibility status and view time based on its
       * visibility on page.
       */
      const updateAdViewTime = () => {
        if (document.hidden) {
          if (!previouslyVisibleAds) {
            previouslyVisibleAds = visibleAds;
            visibleAds = new Set();
            previouslyVisibleAds.forEach(function(adBox) {
              if (adBox.dataset.visible === 'true') {
                updateAdTimer(adBox);
              }
              adBox.dataset.lastViewStarted = 0;
            });
          }
        } else {
          (previouslyVisibleAds || []).forEach(function(adBox) {
            if (adBox.dataset.visible === 'true') {
              adBox.dataset.lastViewStarted = performance.now();
            }
          });
          visibleAds = previouslyVisibleAds || new Set();
          previouslyVisibleAds = null;
        }
      };

      /**
       * Helper to find an Ad INS element in the document by its instance
       * id. The instance id is assigned during Ad initialization.
       *
       * @param {String} adInstanceId
       */
      const getAdByInstanceId = (adInstanceId) => {
        const ads = document.getElementsByClassName('talrooads');
        for (let anAd of ads) {
          if (anAd._ad_instance_id == adInstanceId) {
            return anAd;
          }
        }
        return null;
      }

      /**
       * Handles events from PostMessage requests.
       *
       * @param {MessageEvent} event
       */
      const receiveMessage = (event) => {
        const { type: messageType, data, source } = event.data;

        // Determine the source Ad Unit for the message based on the id
        let source_ad = !!source ? getAdByInstanceId(source) : null;

        if (messageType === 'talroo_redirect') {
          window.location.href = data.dest_url + window.location.search;
        }

        if (messageType === 'widget_event_logging') {

          // send event sent by widget inside frame
          const eMsg = trackingMessage(source_ad, data.type, data.data)
          sendBeaconHelper(eMsg, `${trackingEndpoint}/ad/${source_ad._ad_id}/event`);
        }

        if (messageType === 'event_keyword_click') {
          const adEvent = new CustomEvent('talroo-ad-keyword-click', { detail: { keyword: data.keyword, type: data.type } });
          source_ad.dispatchEvent(adEvent);
        }
      };

      /**
       * When a session on a page where an Ad is loaded enters a `hidden` state
       * we fire this method to send the current Ad lifetime data nd
       * client side data to the tracking service.
       */
      const pageViewEnd = () => {
        if (document.hidden) {
          const ads = [];
          if (visibleAds && visibleAds.size > 0) {
            ads.push(...visibleAds);            
          } else if (previouslyVisibleAds && previouslyVisibleAds.size > 0) {
            ads.push(...previouslyVisibleAds);
          }

          if( ads.length > 0 ){
            // send batch of events for end view for all visible ads
            const eMsg = ads.map((ad) => (trackingMessage(ad, 'page_view_end')));
            sendBeaconHelper(eMsg, `${trackingEndpoint}/ad/event`);
          }
        }
      };

      isInViewport();

      //////////////////////////////////////////////////////////////////////
      // Global Event Listeners                                           //
      //////////////////////////////////////////////////////////////////////

      // For catching Post
      window.addEventListener(
        'message',
        receiveMessage,
        false,
      );

      window.addEventListener(
        'scroll',
        isInViewport,
        false,
      );

      window.addEventListener(
        'resize',
        reportWindowSize,
        false,
      );

      window.addEventListener(
        'visibilitychange',
        pageViewEnd,
        false,
      );

      window.addEventListener(
        'visibilitychange',
        updateAdViewTime,
        false,
      );
    }
  };
})();
